import React from "react";
import styles from "../../styles/Home/home2.module.css";
import {
  Link
} from 'react-router-dom'

export default function hero_2() {
  return (
    <div className={styles.hero}>
      <section className={styles.container}>
        <header className={styles.header}>Our Vision</header>
        <div className={styles.block}>
          The University of Southampton Artificial Intelligence Society is a
          student-led organisation with the purpose of teaching everyone about
          AI regardless of their previous experience. We offer weekly talks,
          workshops, projects, and an AIExpo. Our HackAI event is a 48-hour
          hackathon for students to work on AI-based projects. Join us today and
          explore the possibilities of AI!
        </div>
        <Link to="/about" className={styles.button}>
          Our Team
        </Link>
      </section>
    </div>
  );
}
