import React, { useCallback, useEffect, useState } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import particlesOptions from "../misc/particles/particles.json";
import styles1 from "../styles/Home/home4.module.css";
import Cirium from "../images/cirium.png";

const HackAI = () => {
  const particlesInit = useCallback((main) => {
    loadFull(main);
  }, []);

  const [timeUntilHackathon, setTimeUntilHackathon] = useState("");
  const [timeUntilEndOfHackathon, setTimeUntilEndOfHackathon] = useState("");
  const [hackathonStatus, setHackathonStatus] = useState(
    "Time until hackathon"
  );

  useEffect(() => {
    const hackathonStart = new Date("2024-02-10T11:00:00");
    const hackathonEnd = new Date("2024-02-11T15:00:00");
    const now = new Date();

    if (now < hackathonStart) {
      const timeDiff = hackathonStart - now;
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
      setTimeUntilHackathon(`${hours}h ${minutes}m ${seconds}s`);
    } else if (now >= hackathonStart && now < hackathonEnd) {
      const timeDiff = hackathonEnd - now;
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
      setTimeUntilEndOfHackathon(`${hours}h ${minutes}m ${seconds}s`);
      setHackathonStatus("Time until end of hackathon");
    } else {
      setHackathonStatus("End of hackathon");
    }

    const interval = setInterval(() => {
      const now = new Date();
      if (now < hackathonStart) {
        const timeDiff = hackathonStart - now;
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        setTimeUntilHackathon(`${hours}h ${minutes}m ${seconds}s`);
      } else if (now >= hackathonStart && now < hackathonEnd) {
        const timeDiff = hackathonEnd - now;
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        setTimeUntilEndOfHackathon(`${hours}h ${minutes}m ${seconds}s`);
        setHackathonStatus("Time until end of hackathon");
      } else {
        setHackathonStatus("End of hackathon");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          width: "100%",
          height: "100vh",
        }}
      >
        {
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "13rem",
            }}
          >
            <div
              style={{
                color: "white",
                textAlign: "center",
                fontSize: "3rem",
                fontWeight: "bold",
              }}
            >
              <h3>Hack AI 2024</h3>
              {hackathonStatus === "Time until hackathon" && (
                <h3>
                  {hackathonStatus} : {timeUntilHackathon}
                </h3>
              )}
              {hackathonStatus === "Time until end of hackathon" && (
                <h3>
                  {hackathonStatus} : {timeUntilEndOfHackathon}
                </h3>
              )}
              {hackathonStatus === "End of hackathon" && (
                <h3>{hackathonStatus}</h3>
              )}
              <p style={{ textAlign: "center", fontSize: "2rem" }}>
                Top winners secure a fully paid internship
              </p>
            </div>
          </div>
        }
      </div>
      <div className={styles1.hero3} style={{ marginTop: "10rem" }}>
        <section className={styles1.container}>
          <header className={styles1.header}>Our Sponsors</header>
          <a href="https://www.cirium.com/" className={styles1.imgblock}>
            <div>
              <img src={Cirium} className={styles1.img_con} />
            </div>
          </a>
        </section>
      </div>
      <div
        style={{
          color: "white",
          textAlign: "center",
          fontSize: "2rem",
          height: "20rem",
          fontWeight: "bold",
          marginTop: "2rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h3>Join Now </h3>
        <a
          href="https://forms.office.com/e/AWF47s8cB2"
          className={styles1.buttonop}
        >
          Join Now
        </a>
      </div>

      <Particles options={particlesOptions} init={particlesInit} />
    </div>
  );
};

export default HackAI;
