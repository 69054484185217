import React from "react";
import styles from "../../styles/Events/events.module.css";
import HoverComponent from "./hover";
import ClickComponent from "./click";
import first from "../../images/Talk_with_mercedes_a_c.jpg"
import second from "../../images/Quantitive_Finance_and_AI.jpg"

export default function events() {
  const events = [];
  const eventInfo = [
      /*{
      title: "Fish On Toast",
      img: third,
      aria: "Fish On Toast",
      link: true, 
      linkval: "https://forms.office.com/Pages/ResponsePage.aspx?id=-XhTSvQpPk2-iWadA62p2IshUbcg2xNMpoMVPCX1U15UMlMwUUJDQVlZSk40UUk0TUk1WFRPQjk0Vy4u"
    },
    {
      title: "ML for JP Morgan",
      img: first,
      aria: "ML for JP Morgan",
      link: true,
      linkval: "https://forms.office.com/pages/responsepage.aspx?id=-XhTSvQpPk2-iWadA62p2OD5weC52QhGi0MEtWlLhwNUQTRCVEI5UDhXN0hBWjBBN1RYUVRWRjBMRy4u"
    }
    {
      title: "Logistic Regression Event",
      img: first,
      aria: "Logistic Regression Event",
      link: true,
      linkval: "https://forms.office.com/e/Ye4N1r6QzD"
    }*/
    {
      title: "Talk with Dr Mercedes A C",
      img: first,
      aria: "Talk with Dr Mercedes A C",
      link: true,
      linkval: "https://forms.office.com/pages/responsepage.aspx?id=-XhTSvQpPk2-iWadA62p2OD5weC52QhGi0MEtWlLhwNUQkMxVFFONUZXSjBVNkROMkU2UDVUSU5QVC4u"
    }, 
    {
      title: "Quantitive Finance Talk",
      img: second,
      aria: "Quantitive Finance",
      link: false
    }
  ];

  eventInfo.forEach((obj) => {
    events.push(
    <div key={obj.title+obj.aria}>
        <HoverComponent obj={obj} key={obj.title+"pc"+obj.aria}/>
        <ClickComponent obj={obj} key={obj.title+"mobile"}/>
    </div>
    );
  });

  return (
    <>
      <div className={styles.hero}>
        <section className={styles.container}>
          <header className={styles.header}>Upcoming Events</header>
          <section className={styles.events}>{events}</section>
        </section>
      </div>
    </>
  );
}
