import React from 'react';

import Hero_1 from '../components/home/hero_1';
import Hero_2 from '../components/home/hero_2';
import Hero_3 from '../components/home/hero_3';
import Hero_4 from '../components/home/hero_4';

import ScrollToTop from '../misc/scroll';

function Home() {

  return (
    <>
      <ScrollToTop/>
      <Hero_1 />
      <Hero_2 />
      <Hero_3 />
      <Hero_4 />
    </>
  );
}

export default Home;
