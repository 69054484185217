import React from "react";
import styles from "../../styles/Navbar/navbar.module.css";
import logo from "../../images/Logo60.png"

import {
  NavLink
} from 'react-router-dom'

import { BiMenu } from "react-icons/bi";

import Mobile_nav from "./mobile";

export default function Navbar() {
  const navbar = ["Hack-AI", "Home", "About", "Opportunities", "Events"];
  const navlinks = [];
  return (
    <nav className={styles.body}>
      <main className={styles.container}>
        <section className={styles.section1}>
          <div className={styles.logo}>
            <img src={logo} />
          </div>
          <div className={styles.title}>University Of Southampton</div>
        </section>
        <section className={styles.section2}>
          {navbar.forEach((item) => {
            navlinks.push(<NavLink to={item === "home" ? "/" : `/${item.toLowerCase()}`}  className={styles.navlink} key={item}>{item}</NavLink>);
          })}
          {navlinks}
        </section>
        <section className={styles.menu}>
          <BiMenu className={styles.white}/>
        </section>
      </main>
      <Mobile_nav/>
    </nav>
  );
}
