import React from "react";
import Hero_1 from "../components/events/main.js";
import Hero_2 from "../components/events/events"

import ScrollToTop from '../misc/scroll';

export default function Team() {
  return (
    <>
      <ScrollToTop/>
      <Hero_1 />
      <Hero_2 />
    </>
  );
}
