import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom'

import Home from "./pages/Home"
import About from "./pages/About";
import Event from './pages/Events';
import Opportunity from './pages/Opportunities'
import RootLayout from './layout/root';
import SignUp from './pages/SignUp';
import HackAI from './pages/HackAI';
import HackathonTimer from './pages/AiHackathonTimer';
const routesWithRootLayout = (
  <Route path='/' element={<RootLayout />}>
    <Route index element={<Home />} />
    <Route path='about' element={<About />} />
    <Route path='opportunities' element={<Opportunity />} />
    <Route path='events' element={<Event />} />
    <Route path='signup/:eventID' element={<SignUp />} />
    <Route path='*' element={<Home/>} />
  </Route>
);
const routesWithoutRootLayout = (
  <Route path='hack-ai' >
    <Route index element={<HackAI />} />
    <Route path='timer' element={<HackathonTimer />} />
  </Route>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
        {routesWithRootLayout}
    {routesWithoutRootLayout}
    </>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
