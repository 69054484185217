import React from 'react'
import styles from '../../styles/Home/home3.module.css'
import {
  Link
} from 'react-router-dom'
import latest from "../../images/Talk_with_mercedes_a_c.jpg"
  export default function hero_3() {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          Next Event
        </div>
        <div className={styles.main}>
          <img src={latest} alt='the latest event' className={styles.latest}/>
          <div className={styles.button_con}>
          <a href="https://forms.office.com/pages/responsepage.aspx?id=-XhTSvQpPk2-iWadA62p2OD5weC52QhGi0MEtWlLhwNUQkMxVFFONUZXSjBVNkROMkU2UDVUSU5QVC4u" className={styles.button}>
            Sign Up
          </a>
          <Link to="/events" className={styles.button}>
            Events
          </Link>
          </div>
        </div>
      </div>
    )
  }
  