import React from "react";
import logo from "../../images/LogoLarge.png";
import styles from "../../styles/Home/home1.module.css";

import { FaDiscord, FaLinkedinIn, FaInstagram, FaFacebookF, FaGithub, FaLink} from "react-icons/fa";
export default function hero_1() {
  const buttonValues = {
    discord: [<FaDiscord className={styles.icons}/>, "https://discord.gg/6ueny233XV"],
    instagram: [<FaInstagram className={styles.icons}/>, "https://www.instagram.com/sotonaisoc/"],
    facebook: [<FaFacebookF className={styles.icons}/>, "https://www.facebook.com/UniversityofSouthamptonAISociety"],
    linkedIn: [<FaLinkedinIn className={styles.icons}/>, "https://www.linkedin.com/company/aisoc/"],
    github: [<FaGithub className={styles.icons}/>, "https://github.com/aisoc"],
    linktree: [<FaLink className={styles.icons}/>, "https://linktr.ee/AISoc"],
  };
  const buttons = [];
  for (var key in buttonValues) {
    let buttonData = buttonValues[key];
    buttons.push(
      <a
        href={buttonData[1]}
        className={styles.button2}
        key={key}
      >
        {buttonData[0]}
      </a>
    );
  }
  return (
    <div className={styles.hero}>
      <div className={styles.container}>
        <section className={styles.block3}>
          <div>
            <img src={logo} className={styles.imgblock} />
          </div>
        </section>
        <section className={styles.block1}>
          <div className={styles.textblock}>Artificial Intelligence Society</div>
          <div className={styles.button_container}>
          {buttons}
          </div>
          <a
            href="https://www.susu.org/groups/artificial-intelligence-society"
            className={styles.button1}
          >
            Join
          </a>
        </section>

        <section className={styles.block2}>
          <div>
            <img src={logo} className={styles.imgblock} />
          </div>
        </section>
      </div>
    </div>
  );
}
