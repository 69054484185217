import React, { useState } from "react";
import styles from "../../styles/Events/events.module.css";
const ParentComponent = (props) => {
  const [isHovered, setIsHovered] = useState(false);


  const handleClick = () => {
    setIsHovered(!isHovered);
  };

  const obj = props.obj;

  return (
    <div
      onClick={handleClick}
      className={styles.mobile}
      key={obj.title+obj.aria}
    >
      <div key={obj.title} className={styles.event_title}>
        {obj.title}
      </div>
      <div className={styles.img_block}>
        <img src={obj.img} key={obj.aria+obj.title} alt={obj.aria} className={styles.img_container} />
        {isHovered && obj.link && <ChildComponent key={obj.title+obj.title} obj={obj}/>}
      </div>
    </div>
  );
};

const ChildComponent = (props) => {
  const obj = props.obj;
  return (
    <a href={obj.linkval} className={styles.button}>
      Sign Up
    </a>
  );
};

export default ParentComponent;
