import React from "react";
import styles from "../../styles/Events/main.module.css";
import { Link } from "react-router-dom";
import latest from "../../images/Talk_with_mercedes_a_c.jpg"
export default function main() {
  return (
    <div className={styles.hero}>
      <div className={styles.container}>
        <section className={styles.block}>
          <header className={styles.header1}>Events</header>
          <div className={styles.headertext}>
            The AI Society at the University of Southampton is dedicated to
            organising a diverse range of events.
          </div>
          <div className={styles.headertext}>
            Our events are designed to promote learning, collaboration, and
            engagement with artificial intelligence (AI) in various forms. From
            informative workshops and seminars to hackathons, our events offer
            opportunities for both beginners and experienced individuals to
            explore AI technologies and applications
          </div>
          <header className={styles.header2}>Next Events</header>
          <div className={styles.img_block}>
            <img
              src={latest}
              className={styles.img_container}
              alt=""
            />
              <a 
                href="https://forms.office.com/pages/responsepage.aspx?id=-XhTSvQpPk2-iWadA62p2OD5weC52QhGi0MEtWlLhwNUQkMxVFFONUZXSjBVNkROMkU2UDVUSU5QVC4u"
                className={styles.button}>
                Sign Up
              </a>
          </div>
        </section>
      </div>
    </div>
  );
}
