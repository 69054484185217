import React, { useState, useEffect } from 'react'

import styles from "../../styles/Navbar/navbar.module.css";
import logo from "../../images/Logo60.png"

import {
  NavLink,
  useLocation
} from 'react-router-dom'

import { BiMenu } from "react-icons/bi";

export default function Mobile_nav() {
    const [toggle, handleToggle] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
      handleToggle(false)
    }, [pathname]);

    const navbar = ["Hack-AI", "Home", "About", "Opportunities", "Events"];
    const navlinks = [];
    return (
      <nav className={styles.mobile_body}>
        <main className={styles.mobile_container}>
          <section className={styles.section1}>
            <div className={styles.logo}>
              <img src={logo} />
            </div>
            <div className={styles.title}>University Of Southampton</div>
          </section>
          <section className={styles.menu}>
            <BiMenu className={styles.white} onClick={()=>handleToggle(!toggle)}/>
          </section>
        </main>
        <section className={ toggle ? styles.mobile_section : styles.none}>
            {navbar.forEach((item) => {
              navlinks.push(<NavLink to={item === "Home" ? "/" : `/${item}`}  onClick={()=>handleToggle(false)}className={styles.mobile_navlink} key={item}>{item}</NavLink>);
            })}
            {navlinks}
          </section>
      </nav>
    );
}
