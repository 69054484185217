import React, { useState, useEffect, useCallback } from "react";
import logo from "../images/LogoLarge.png";
import styles from "../styles/Home/home1.module.css";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import {Outlet} from 'react-router-dom'
import particlesOptions from "../misc/particles/particles.json"

const HackathonTimer = () => {
  const particlesInit = useCallback(main => {
    loadFull(main);
  }, [])

  const [timeUntilHackathon, setTimeUntilHackathon] = useState("");
  const [timeUntilEndOfHackathon, setTimeUntilEndOfHackathon] = useState("");
  const [hackathonStatus, setHackathonStatus] = useState(
    "Time until hackathon"
  );

  useEffect(() => {
    const hackathonStart = new Date("2024-02-10T11:00:00");
    const hackathonEnd = new Date("2024-02-11T15:00:00");
    const now = new Date();

    if (now < hackathonStart) {
      const timeDiff = hackathonStart - now;
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
      setTimeUntilHackathon(`${hours}h ${minutes}m ${seconds}s`);
    } else if (now >= hackathonStart && now < hackathonEnd) {
      const timeDiff = hackathonEnd - now;
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
      setTimeUntilEndOfHackathon(`${hours}h ${minutes}m ${seconds}s`);
      setHackathonStatus("Time until end of hackathon");
    } else {
      setHackathonStatus("End of hackathon");
    }

    const interval = setInterval(() => {
      const now = new Date();
      if (now < hackathonStart) {
        const timeDiff = hackathonStart - now;
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        setTimeUntilHackathon(`${hours}h ${minutes}m ${seconds}s`);
      } else if (now >= hackathonStart && now < hackathonEnd) {
        const timeDiff = hackathonEnd - now;
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        setTimeUntilEndOfHackathon(`${hours}h ${minutes}m ${seconds}s`);
        setHackathonStatus("Time until end of hackathon");
      } else {
        setHackathonStatus("End of hackathon");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        color: "white",
        height: "100vh",
        width: "100wh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "3vw",
        flexDirection: "column"
      }}
    >
      <div>
        <img src={logo} className={styles.imgblock} />
      </div>
      {hackathonStatus === "Time until hackathon" && (
        <h2>
          {hackathonStatus} : {timeUntilHackathon}
        </h2>
      )}
      {hackathonStatus === "Time until end of hackathon" && (
        <h2>
          {hackathonStatus} : {timeUntilEndOfHackathon}
        </h2>
      )}
      {hackathonStatus === "End of hackathon" && <h2>{hackathonStatus}</h2>}
      <Particles options={particlesOptions} init={particlesInit} />
    </div>
  );
};

export default HackathonTimer;
