import React, { useState } from "react";
import styles from "../../styles/Events/events.module.css";

const ParentComponent = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const obj = props.obj;

  return (
    <div className={styles.non_mobile}>
      <div key={obj.title + obj.aria} className={styles.event_title}>
        {obj.title}
      </div>
      <div
        className={styles.img_block}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        key={obj.title + obj.aria + obj.title}
      >
        <img src={obj.img} alt={obj.aria} className={`${styles.img_container}`}/>
        {isHovered && obj.link && (
          <a href={obj.linkval} className={`${styles.button}`}>
            Sign Up
          </a>
        )}
      </div>
    </div>
  );
};


export default ParentComponent;
