import React from "react";
import styles from "../styles/About/about.module.css";

import ScrollToTop from "../misc/scroll";

import Antonio from "../images/Antonio.png"
import Sarvesh from "../images/Sarvesh.png"
import Harry from "../images/Harry.png"
import Dhruv from "../images/Dhruv.jpg"
import Jakub from "../images/Jakub.png"
import Athrava from "../images/Atharva.png"
import Dwi from "../images/Dwi.jpg"
import Parth from "../images/Parth.png"
import David from "../images/David.png"
export default function About() {

  const teamInfo = [
    {
      name: "Dhruv Khanna",
      title: "President",
      image: Dhruv,
    },
    {
      name: "Parth Chutani",
      title: "Vice President",
      image: Parth,
    },
    {
      name: "David Shepherd",
      title: "Secretary",
      image: David,
    },
    {
      name: "Antonio Cardoso Pinto",
      title: "Treasurer",
      image: Antonio,
    },
    {
      name: "Sarvesh Mina",
      title: "Head Of Education",
      image: Sarvesh,
    },
    {
      name: "Jakub Nowacki",
      title: "Head Of Projects",
      image: Jakub,
    },
    {
      name: "Dwijesh Dookraz",
      title: "Head Of Marketing",
      image: Dwi,
    },
    {
      name: "Atharva Pawar",
      title: "Head Of Partnerships",
      image: Athrava,
    },
    {
      name: "Harry Leslie",
      title: "Web Officer",
      image: Harry
    }
  ];
  const team = [];

  teamInfo.forEach((obj) => {
    team.push(
      <div className={styles.container} key={obj.name}>
        <div>
          {obj.image != "None" ? (
            <img src={obj.image} className={styles.image_container} />
          ) : (
            <div className={styles.template}></div>
          )}
        </div>
        <div className={styles.text}>{obj.name}</div>
        <div className={styles.text}>{obj.title}</div>
      </div>
    );
  });
  return (
    <>
      <ScrollToTop />
      <div className={styles.hero}>
        <div className={styles.container_main}>
          <section className={styles.block1}>
            <div className={styles.textblock}>Our Team</div>
            <div className={styles.headertext}>
              We are a vibrant community of students passionate about artificial
              intelligence (AI). Through engaging events, workshops, and
              lectures, we provide learning opportunities and foster
              collaboration among students from diverse disciplines
            </div>
          </section>
        </div>
      </div>
      <div className={styles.flex}>
      <div className={styles.team}>
        {team}
      </div>
      </div>
    </>
  );
}
