import React, { useCallback } from 'react';
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import {Outlet} from 'react-router-dom'

import Navbar from '../components/navbar'


import particlesOptions from "../misc/particles/particles.json"
export default function RootLayout() {
  const particlesInit = useCallback(main => {
    loadFull(main);
  }, [])
  return (
    <>
    <Navbar />
    <div className='main'>
        <Outlet />
    </div>
    <Particles options={particlesOptions} init={particlesInit}/>
    </>
  )
}
