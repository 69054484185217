import React from "react";
import ScrollToTop from '../misc/scroll';

export default function Opportunities() {
  return (
    <div className="width-100 bg-white flex">
        <ScrollToTop/>
    <div className="text-main">Opportunities</div>
    <div className="description">Here, we provide you with a gateway to exciting career prospects and job
      opportunities in the field of artificial intelligence (AI). Through our
      collaboration with our esteemed sponsor, we present a curated selection of
      AI-related job listings to help you explore</div>
      <script
        type="text/javascript"
        src="https://stepstone.stellenplattform.net/plugin/js/ae80c9c704e90732018e1d2abe6c613e/220.js"
      ></script>
      <iframe
        className="width-90"
        height="1000px"
        src="https://stepstone.stellenplattform.net/plugin/ae80c9c704e90732018e1d2abe6c613e/220"
      ></iframe>
    </div>
  );
}
