import React from "react";
import styles from "../../styles/Home/home4.module.css";
import { Link } from "react-router-dom";
import StepStone from "../../images/Stepstone.png";
export default function hero_4() {
  return (
    <div className={styles.hero2}>
      <section className={styles.container}>
        <header className={styles.header}>Our Sponsors</header>
        <a href="https://www.stepstone.de/en/" className={styles.imgblock}>
          <div>
            <img src={StepStone} className={styles.img_con} />
          </div>
        </a>
        <Link to="/opportunities" className={styles.buttonop}>
          Opportunities
        </Link>
      </section>
    </div>
  );
}
